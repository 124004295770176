@import "../theme/mixins";

$brand: "hulala";
$brandAssets: "/assets/#{$brand}";
:root {
  --brand: $brand;
  --ion-color-main: #3e3e3e;
  --ion-color-second: #7e7e7e;
  --ion-color-desc: #6b6b6b;
}

@font-face {
  font-family: AppFont;
  src: url("../assets/font/Raleway-Regular.ttf");
}
@font-face {
  font-family: AppFontBold;
  src: url("../assets/font/Raleway-Bold.ttf");
}
@font-face {
  font-family: AppFontLight;
  src: url("../assets/font/Raleway-Light.ttf");
}
@font-face {
  font-family: AppFontTitle;
  src: url("../assets/font/DMSerifDisplay-Regular.ttf");
}
